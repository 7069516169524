import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { store } from './';
import { actions } from 'app/containers/App/slice';
import { getItem } from 'utils/localStorage';
import { TYPE_LOCAL_STORAGE } from 'utils/constants';

const authLink = setContext((_, { headers }) => {
  const token = getItem(TYPE_LOCAL_STORAGE.TOKEN);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});
const authLinkServiceUser = setContext((_, { headers }) => {
  const token = getItem(TYPE_LOCAL_STORAGE.TOKEN);
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : '',
    },
  };
});

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors);
    graphQLErrors.forEach(({ message, locations, path }) => {
      store.dispatch(
        actions.setOptionNotification({
          type: 'error',
          message: message || 'Lỗi',
        }),
      );
      store.dispatch(actions.changeIsOpenNotification(true));
      console.log(
        `[GraphQL error]: Message: ${JSON.stringify(
          message,
        )}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(
          path,
        )}`,
      );
    });
  }
  if (networkError) {
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      store.dispatch(
        actions.setOptionNotification({
          type: 'error',
          message: 'Hết phiên đăng nhập',
        }),
      );
      store.dispatch(actions.changeIsOpenNotification(true));
      store.dispatch(actions.logout());
    }
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL_GRAPHQL + '/graphql',
});
const httpLinkServiceUser = createHttpLink({
  uri: process.env.REACT_APP_API_URL_GRAPHQL_SERVICE_USER + '/graphql',
});

export const clientGraphQL = new ApolloClient({
  // link: ApolloLink.from([link, authLink.concat(httpLink)]),
  link: ApolloLink.from([
    link,
    ApolloLink.split(
      operation => operation.getContext().clientName === 'service-user',
      authLinkServiceUser.concat(httpLinkServiceUser),
      authLink.concat(httpLink),
    ),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
