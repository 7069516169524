export enum TYPE_LOCAL_STORAGE {
  URL_REDIRECT = 'admin_url_redirect',
  TOKEN = 'admin-token',
}

export const SIDE_MENU_WIDTH = 300;

export const PAGE_SIZE = 10;

export enum TYPE_ACTION_FORM {
  create = 'Tạo mới',
  update = 'Sửa đổi',
}
