import axios, {
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from 'axios';
import { getItem } from 'utils/localStorage';
import Qs from 'qs';
import { TYPE_LOCAL_STORAGE } from 'utils/constants';
import { store } from '../';
import { actions } from 'app/containers/App/slice';

const onSuccessInterceptorRequest = async config => {
  const token = await getItem(TYPE_LOCAL_STORAGE.TOKEN);
  if (token) config.headers['api-token'] = `${token}`;
  config.paramsSerializer = (params: any) =>
    Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    });
  return config;
};
const onErrorInterceptorRequest = (error: AxiosError) => Promise.reject(error);

const onErrorInterceptorResponse = (error: AxiosError) => {
  if (error.response && error.response.status) {
    if (error.response.status !== 200) {
      store.dispatch(
        actions.setOptionNotification({
          type: 'error',
          message: error.response?.data?.message || 'Lỗi',
        }),
      );
      store.dispatch(actions.changeIsOpenNotification(true));
      if (error.response.status === 401) {
        store.dispatch(actions.logout());
      }
    }
  }
  return Promise.reject(error);
};
const onSuccessInterceptorResponse = (response: AxiosResponse) => response;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

const _axios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  timeout: 120 * 1000,
  // withCredentials: true, // Check cross-site Access-Control
});

_axios.interceptors.request.use(
  onSuccessInterceptorRequest,
  onErrorInterceptorRequest,
);

_axios.interceptors.response.use(
  onSuccessInterceptorResponse,
  onErrorInterceptorResponse,
);

/**
 * NOTE: axios instance (another token)
 * for that case join room with JWT
 */
export const _axiosGraphQL: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_GRAPHQL || '',
  timeout: 120 * 1000,
});

_axiosGraphQL.interceptors.request.use(
  onSuccessInterceptorRequest,
  onErrorInterceptorResponse,
);

_axiosGraphQL.interceptors.response.use(
  onSuccessInterceptorResponse,
  onErrorInterceptorResponse,
);

/**
 *
 * @NOTE primary methods axios
 *
 */
class AxiosXHRConstructor {
  axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.$get = this.$get.bind(this);
    this.$post = this.$post.bind(this);
    this.$put = this.$put.bind(this);
    this.$delete = this.$delete.bind(this);
  }
  public $get(url: string, params?: AxiosRequestConfig): AxiosPromise {
    return this.axiosInstance.get(url, {
      params,
    });
  }
  public $post(url: string, config?: AxiosRequestConfig): AxiosPromise {
    return this.axiosInstance.post(url, config);
  }
  public $put(url: string, config?: AxiosRequestConfig): AxiosPromise {
    return this.axiosInstance.put(url, config);
  }
  public $delete(url: string, config?: AxiosRequestConfig): AxiosPromise {
    return this.axiosInstance.delete(url, { data: config || {} });
  }
}

export const BaseXHR = new AxiosXHRConstructor(_axios);
export const GraphqlXHR = new AxiosXHRConstructor(_axiosGraphQL);

export default _axios;
